import React from "react"
import {Link} from "gatsby"

class Navbar extends React.Component {
    constructor() {
        super();
        this.state = {
            isActive: false
        }
    }
    render() {
        const { isActive } = this.state;
        let flipOn = "navbar-menu";
        function handleClick() {
            this.setState({
                isActive: !isActive
            });
            console.log(isActive);
            flipOn = isActive ? "navbar-menu is-active": "navbar-menu";
            console.log(flipOn)
        }
        const title = this.props.title
        return (
            <nav className="column navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link className="navbar-item title" to="/">{title}
                </Link>
                {/* <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" onClick={handleClick}>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                </a> */}
            </div>
            </nav>
        )
    }
}
export default Navbar