import React from 'react'
import { Link } from 'gatsby'

class Aside extends React.Component {
    render() {
        return (
        <aside className="column is-one-fifth ">
            <ul className="menu-list">
                <li><Link to='/' activeClassName='is-active'>Home</Link></li>
                <li><Link to='/blog' activeClassName='is-active'>Blog</Link></li>
            </ul>
        </aside>
        )
    }
}
export default Aside;